export default [
    {
        type: "selection",
        width: 38,
        align: "center",
    },

    {
        title: 'Name',
        key: 'Name',
        minWidth: 80,
    },

    {
        title: 'Bread Crumb',
        key: 'BreadCrumb',
        minWidth: 80,
    },

    {
        title: 'File Path',
        key: 'FilePath',
        minWidth: 80,
    }
]
